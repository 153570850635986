import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "./components/NavBar/index.js";
import ProductsBrowse from "./components/ProductsBrowse";

function App() {
        return (
                <div>
                        <NavBar />
                        <h1>This website is under construction.</h1>
                        <p>Please check back later for updates.</p>
                        <ProductsBrowse />
                </div>
        );
}

export default App;
