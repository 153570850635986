import React from "react";
import "../../styles/ProductsBrowse.css";
import HeroImage from "../../assets/hero.jpg";

export default function ProductsBrowse() {
        return (
                <div>
                        <div className="hero_product_row">
                                <div className="hero_product_row_item">
                                        <div className="hero_product_row_item_image">
                                                <img src={HeroImage} alt="placeholder" />
                                        </div>
                                        <div className="hero_product_row_item_title">Product Title</div>
                                </div>
                        </div>
                        <div className="top_product_row">
                                <div className="top_product_row_item">
                                        <div className="top_product_row_item_image">
                                                <img src="https://via.placeholder.com/150" alt="placeholder" />
                                        </div>
                                        <div className="top_product_row_item_title">Product Title</div>
                                </div>
                                <div className="top_product_row_item">
                                        <div className="top_product_row_item_image">
                                                <img src="https://via.placeholder.com/150" alt="placeholder" />
                                        </div>
                                        <div className="top_product_row_item_title">Product Title</div>
                                </div>
                                <div className="top_product_row_item">
                                        <div className="top_product_row_item_image">
                                                <img src="https://via.placeholder.com/150" alt="placeholder" />
                                        </div>
                                        <div className="top_product_row_item_title">Product Title</div>
                                </div>
                        </div>
                </div>
        );
}
