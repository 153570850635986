import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
import "../../styles/NavBar.css";

function NavBar() {
        return (
                // variant="dark" gives the light colored button on mobile
                <Navbar collapseOnSelect expand="lg" variant="dark" className="main_top_navbar">
                        <Container>
                                <Navbar.Brand href="#home">SiteName.com</Navbar.Brand>
                                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                                <Navbar.Collapse id="responsive-navbar-nav">
                                        <Nav className="me-auto">
                                                <Nav.Link href="#features">Section 1</Nav.Link>
                                                <Nav.Link href="#pricing">Section 2</Nav.Link>
                                        </Nav>
                                        <Nav>
                                                <NavDropdown title="Account" id="collasible-nav-dropdown">
                                                        <NavDropdown.Item href="#action/3.1">Orders</NavDropdown.Item>
                                                        <NavDropdown.Item href="#action/3.2">Account Info</NavDropdown.Item>
                                                        <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                                                        <NavDropdown.Divider />
                                                        <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                                                </NavDropdown>
                                        </Nav>
                                </Navbar.Collapse>
                        </Container>
                </Navbar>
        );
}

export default NavBar;
